import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { http } from '@/utils/http';
import { mergeProps } from '@/utils/merge';
import { ComponentProps } from 'react';
import SubscriptionDetails from './SubscriptionDetails';
export interface StandaloneSubscriptionDetailsProps extends StandaloneComponentProps<typeof SubscriptionDetails> {
  digitalSubscriptionAccordionProps?: ComponentProps<typeof SubscriptionDetails.Accordion>;
  printSubscriptionAccordionProps?: ComponentProps<typeof SubscriptionDetails.Accordion>;
}

interface Payment {
  id: string;
  date: string;
  description: string;
  amount: number;
  currency: string;
}

interface GetPaymentsData {
  url?: string;
  status?: 'active' | 'cancelled';
  alltIdUserCode?: string;
}

interface CancelSubscriptionData {
  url?: string;
  subscriptionId: string;
}

export interface GetPaymentsResponse {
  subscriptionId: string;
  startDate?: string;
  endDate?: string;
  cancelDate?: string | null;
  status?: 'active' | 'cancelled';
  payments?: Payment[];
}

export const getPaymentsItems = async function ({
  url = '/api/payments/subscription-details',
  status = 'active',
}: GetPaymentsData = {}) {
  const response = await http.get<GetPaymentsResponse>(`${url}?${new URLSearchParams({ status }).toString()}`);
  return response?.data;
};

export const cancelSubscription = async function ({
  url = '/api/payments/cancel-subscription',
  subscriptionId,
}: CancelSubscriptionData) {
  await http.post(`${url}?${new URLSearchParams({ subscriptionId }).toString()}`);
};

export const StandaloneSubscriptionDetails: StandaloneComponent<StandaloneSubscriptionDetailsProps> = ({
  digitalSubscriptionAccordionProps,
  printSubscriptionAccordionProps,
  ...props
}) => {
  return (
    <SubscriptionDetails {...props}>
      {(digitalSubscriptionAccordionProps || printSubscriptionAccordionProps) && (
        <SubscriptionDetails.AccordionGroup>
          {digitalSubscriptionAccordionProps && (
            <SubscriptionDetails.Accordion
              {...mergeProps(digitalSubscriptionAccordionProps, {
                options: props.options?.$accordion,
              })}
            />
          )}
          {printSubscriptionAccordionProps && (
            <SubscriptionDetails.Accordion
              {...mergeProps(printSubscriptionAccordionProps, {
                options: props.options?.$accordion,
              })}
            />
          )}
        </SubscriptionDetails.AccordionGroup>
      )}
    </SubscriptionDetails>
  );
};
